import { isBrowser } from 'react-device-detect';
import {
  getMicroServiceURL,
  // getCurrentLanguage,
  getMobileOperatingSystem,
  getWebsiteCountry
} from 'utils';
import isArabic from './isArabic';

const {
  STYLI_APP_API,
  STYLI_APP_PAYMENT_DOMAIN,
  STYLI_APP_WEBSITE_DOMAIN,
  STYLI_APP_UTILS_DOMAIN,
  STYLI_APP_OTP_API,
  STYLI_APP_APPLE_PAY_MERCHANT_ID,
  STYLI_APP_USE_MICROSERVICE,
  STYLI_APP_MICROSERVICE_DOMAIN,
  STYLI_APP_BUCKET_URL,
  STYLI_APP_RECOMMENDATION_DOMAIN,
  STYLI_APP_CLICKSTREAM_DOMAIN,
  STYLI_APP_CLICK_STREAM_RECOMM_URL,
  STYLI_APP_ADDRESS_MAPPER_DOMAIN,
  STYLI_APP_ELASTIC_BASE_URL,
  STYLI_APP_RC_CACHE_TIME,
  STYLI_APP_MULIN_BASE_URL,
  STYLI_APP_DOMAIN,
  STYLI_APP_PAYMENT_SERVICE_DOMAIN,
  STYLI_APP_APPLE_SIGIN_CLIENT_ID,
  STYLI_APP_APPLE_SIGIN_REDIRECT_URI
} = process.env;

export const ENABLE_PROMPTS = true;

export const API_DOMAIN = STYLI_APP_API;
export const RC_CACHE_TIME = STYLI_APP_RC_CACHE_TIME;

// export const MICROSERVICE_DOMAIN = STYLI_APP_MICROSERVICE_DOMAIN;
export const PAYMENT_SERVICE_DOMAIN = STYLI_APP_PAYMENT_SERVICE_DOMAIN;
export const PAYMENT_DOMAIN = STYLI_APP_PAYMENT_DOMAIN;
export const WEBSITE_DOMAIN = STYLI_APP_WEBSITE_DOMAIN;
export const API_STYLI_UTILS_DOMAIN = STYLI_APP_UTILS_DOMAIN;
export const OTP_API = STYLI_APP_OTP_API;
export const USE_MICROSERVICE = Number(STYLI_APP_USE_MICROSERVICE);
export const BUCKET_URL = STYLI_APP_BUCKET_URL;
export const RECOMMENDATION_DOMAIN = STYLI_APP_RECOMMENDATION_DOMAIN;
export const CLICKSTREAM_DOMAIN = STYLI_APP_CLICKSTREAM_DOMAIN;
export const CLICK_STREAM_RECOMM_URL = STYLI_APP_CLICK_STREAM_RECOMM_URL;
export const ADDRESS_MAPPER_DOMAIN = STYLI_APP_ADDRESS_MAPPER_DOMAIN;
export const ELASTIC_BASE_URL = STYLI_APP_ELASTIC_BASE_URL;
export const MULIN_BASE_URL = STYLI_APP_MULIN_BASE_URL;
export const DOMAIN = STYLI_APP_DOMAIN;

export const APPLE_PAY_MERCHANT_ID = STYLI_APP_APPLE_PAY_MERCHANT_ID;

export const APPLE_SIGIN_CLIENT_ID = STYLI_APP_APPLE_SIGIN_CLIENT_ID;
export const APPLE_SIGIN_REDIRECT_URI = STYLI_APP_APPLE_SIGIN_REDIRECT_URI;

export const ARABIC_LANG = 'ar';
export const ENGLISH_LANG = 'en';
export const isRTL = isArabic;

export const IS_ARABIC = isRTL;
export const CURRENT_LANG_PREFIX = IS_ARABIC ? ARABIC_LANG : ENGLISH_LANG;
export const COUNTRY_KSA = 'sa';
export const COUNTRY_UAE = 'ae';
export const COUNTRY_KWT = 'kw';
export const COUNTRY_BH = 'bh';
export const COUNTRY_QA = 'qa';
export const COUNTRY_IND = 'in';

export const COUNTRIES = [
  COUNTRY_KSA,
  COUNTRY_UAE,
  COUNTRY_KWT,
  COUNTRY_QA,
  COUNTRY_BH,
  COUNTRY_IND
];
export const COUNTRIES_NAMES = [
  'Saudi Arabia',
  'United Arab Emirates',
  'Kuwait',
  'Qatar',
  'Bahrain',
  'India'
];

export const COUNTRIES_SHORT_NAMES = ['KSA', 'UAE', 'KWT', 'QAT', 'BHR', 'IND'];
export const PHONE_CODE = {
  SA: '+966',
  AE: '+971',
  KW: '+965',
  QA: '+974',
  BH: '+973',
  IN: '+91'
};

export const COUNTRY = getWebsiteCountry(COUNTRIES);
export const BASE_PATH = `/${COUNTRY}`;

export const MICROSERVICE_DOMAIN = getMicroServiceURL(
  COUNTRY,
  STYLI_APP_MICROSERVICE_DOMAIN
);

export const ARABIC_STORE_ID = Number(process.env.STYLI_APP_ARABIC_STORE_ID);
export const ENGLISH_STORE_ID = Number(process.env.STYLI_APP_ENGLISH_STORE_ID);

export const UAE_ARABIC_STORE_ID = Number(
  process.env.STYLI_APP_UAE_ARABIC_STORE_ID
);
export const UAE_ENGLISH_STORE_ID = Number(
  process.env.STYLI_APP_UAE_ENGLISH_STORE_ID
);

export const KW_ARABIC_STORE_ID = Number(
  process.env.STYLI_APP_KW_ARABIC_STORE_ID
);

export const KW_ENGLISH_STORE_ID = Number(
  process.env.STYLI_APP_KW_ENGLISH_STORE_ID
);

export const BH_ARABIC_STORE_ID = Number(
  process.env.STYLI_APP_BH_ARABIC_STORE_ID
);

export const BH_ENGLISH_STORE_ID = Number(
  process.env.STYLI_APP_BH_ENGLISH_STORE_ID
);

export const QA_ARABIC_STORE_ID = Number(
  process.env.STYLI_APP_QA_ARABIC_STORE_ID
);

export const QA_ENGLISH_STORE_ID = Number(
  process.env.STYLI_APP_QA_ENGLISH_STORE_ID
);
export const IN_ENGLISH_STORE_ID = Number(
  process.env.STYLI_APP_IN_ENGLISH_STORE_ID
);

let locale = 'en-SA';
let currency = 'SAR';
let websiteId = Number(process.env.STYLI_APP_KSA_WEBSITE_ID);
let fraction = 0;
let countryStoreIDs = [];
switch (COUNTRY) {
  case COUNTRY_UAE:
    locale = 'en-AE';
    currency = 'AED';
    websiteId = Number(process.env.STYLI_APP_UAE_WEBSITE_ID);
    countryStoreIDs = [UAE_ARABIC_STORE_ID, UAE_ENGLISH_STORE_ID];
    break;
  case COUNTRY_KWT:
    locale = 'en-AE';
    currency = 'KWD';
    fraction = 2;
    websiteId = Number(process.env.STYLI_APP_KWT_WEBSITE_ID);
    countryStoreIDs = [KW_ARABIC_STORE_ID, KW_ENGLISH_STORE_ID];
    break;
  case COUNTRY_BH:
    locale = 'en-AE';
    currency = 'BHD';
    fraction = 2;
    websiteId = Number(process.env.STYLI_APP_BH_WEBSITE_ID);
    countryStoreIDs = [BH_ARABIC_STORE_ID, BH_ENGLISH_STORE_ID];
    break;
  case COUNTRY_QA:
    locale = 'en-AE';
    currency = 'QAR';
    fraction = 2;
    websiteId = Number(process.env.STYLI_APP_QA_WEBSITE_ID);
    countryStoreIDs = [QA_ARABIC_STORE_ID, QA_ENGLISH_STORE_ID];
    break;
  case COUNTRY_IND:
    locale = 'en-IN';
    currency = 'INR';
    fraction = 2;
    websiteId = Number(process.env.STYLI_APP_IN_WEBSITE_ID);
    countryStoreIDs = [IN_ENGLISH_STORE_ID];
    break;
  default:
  case COUNTRY_KSA:
    locale = 'en-SA';
    countryStoreIDs = [ARABIC_STORE_ID, ENGLISH_STORE_ID];
    break;
}

export const COUNTRY_STORE_IDS = countryStoreIDs;
export const WEBSITE_ID = websiteId;
export const CURRENCY_FRACTION = fraction;
export const DEFAULT_LOCALE = locale;
export const DEFAULT_CURRENCY = currency;

export const MADA_CARD_BINS = [
  '588845',
  '440647',
  '440795',
  '446404',
  '457865',
  '968208',
  '636120',
  '417633',
  '468540',
  '468541',
  '468542',
  '468543',
  '968201',
  '446393',
  '588847',
  '400861',
  '409201',
  '458456',
  '484783',
  '968205',
  '462220',
  '455708',
  '588848',
  '455036',
  '968203',
  '486094',
  '486095',
  '486096',
  '504300',
  '440533',
  '489317',
  '489318',
  '489319',
  '445564',
  '968211',
  '410685',
  '432328',
  '428671',
  '428672',
  '428673',
  '968206',
  '446672',
  '543357',
  '434107',
  '431361',
  '604906',
  '521076',
  '588850',
  '968202',
  '535825',
  '529415',
  '535825',
  '543085',
  '524130',
  '554180',
  '549760',
  '968209',
  '524514',
  '529741',
  '537767',
  '535989',
  '536023',
  '513213',
  '585265',
  '588983',
  '588982',
  '589005',
  '508160',
  '531095',
  '530906',
  '532013',
  '588851',
  '605141',
  '968204',
  '422817',
  '422818',
  '422819',
  '428331',
  '483010',
  '483011',
  '483012',
  '589206',
  '968207',
  '419593',
  '439954',
  '407197',
  '407395',
  '457997',
  '474491',
  '410621',
  '558563',
  '420132',
  '406136',
  '446404',
  '440795',
  '440647',
  '421141',
  '474491',
  '588845',
  '968208',
  '457997',
  '457865',
  '468540',
  '468541',
  '468542',
  '468543',
  '417633',
  '446393',
  '636120',
  '968201',
  '410621',
  '409201',
  '403024',
  '458456',
  '462220',
  '968205',
  '455708',
  '484783',
  '588848',
  '455036',
  '968203',
  '486094',
  '486095',
  '486096',
  '504300',
  '440533',
  '489318',
  '489319',
  '445564',
  '968211',
  '410685',
  '406996',
  '432328',
  '428671',
  '428672',
  '428673',
  '968206',
  '446672',
  '543357',
  '434107',
  '407197',
  '407395',
  '42689700',
  '412565',
  '431361',
  '604906',
  '521076',
  '588850',
  '968202',
  '529415',
  '535825',
  '543085',
  '524130',
  '554180',
  '549760',
  '968209',
  '524514',
  '529741',
  '537767',
  '535989',
  '536023',
  '513213',
  '520058',
  '558563',
  '605141',
  '968204',
  '422817',
  '422818',
  '422819',
  '410834',
  '428331',
  '483010',
  '483011',
  '483012',
  '589206',
  '968207',
  '406136',
  '419593',
  '439954',
  '407520',
  '530060',
  '531196',
  '420132',
  '242030',
  '22402030',
  '442463'
];

const device = getMobileOperatingSystem();

export const isIOS = device === 'iOS';
export const isAndroid = device === 'Android';
export const OTP_TIMER = 30;
export const MIN_DISCOUNT_VAL = 20;

export const CARD_PAYMENT_INDEX = 0;
export const COD_PAYMENT_INDEX = 1;
export const APPLE_PAYMENT_INDEX = 2;
export const WALLET_PAYMENT_INDEX = 3;
export const TABBY_INSTALLMENTS_INDEX = 4;
export const TABBY_PAYLATER_INDEX = 5;
export const TAMARA_INSTALLMENTS_3_INDEX = 6;
export const TAMARA_INSTALLMENTS_6_INDEX = 7;
export const CASHFREE_PAYMENT_INDEX = 8;
export const JWT_COOKIE_NAME = 'r_jwt';
export const REFRESH_TOKEN = 'r_rwt';
export const DEVICE_ID_COOKIE_NAME = 'r_deviceId';
export const UID_COOKIE_NAME = 'r_uid';

export const PAYMENT_METHODS = [
  'card',
  'cashOnDelivery',
  'applePay',
  'styliCredit',
  'tabbyInstallments',
  'tabbyPaylater',
  'tamaraInstallments3',
  'tamaraInstallments6',
  'cashFree'
];

export const SAVED_CARD_MAX_LIST = 2;
export const PAYMENT_CODE_VAULT = 'md_payfort_cc_vault';
export const PAYMENT_CODES = {
  [CARD_PAYMENT_INDEX]: 'md_payfort', // 'md_payfort',
  [COD_PAYMENT_INDEX]: 'cashondelivery',
  [APPLE_PAYMENT_INDEX]: 'apple_pay',
  [TABBY_INSTALLMENTS_INDEX]: 'tabby_installments',
  [TAMARA_INSTALLMENTS_3_INDEX]: 'tamara_installments_3',
  [TAMARA_INSTALLMENTS_6_INDEX]: 'tamara_installments_6',
  [TABBY_PAYLATER_INDEX]: 'tabby_paylater',
  [CASHFREE_PAYMENT_INDEX]: 'cashfree'
};

export const DEFAULT_SHIPPING_CHARGE = 10;
export const DEFAULT_MIN_BAG_VALUE_FREE_SHIP = 100;

export const IS_DEV = process.env.NODE_ENV === 'development';

export const ALGOLIA_INDEX_PREFIX =
  process.env.NODE_ENV === 'qa' || process.env.NODE_ENV === 'development'
    ? 'qa'
    : 'live';
export const ALGOLIA_APP_ID =
  process.env.NODE_ENV === 'qa' || process.env.NODE_ENV === 'development'
    ? 'testing20LAJL5O48'
    : 'OWC9I5QTIE';
export const ALGOLIA_SEARCH_KEY =
  process.env.NODE_ENV === 'qa' || process.env.NODE_ENV === 'development'
    ? '57482959f93154e26b729490ad7d2a13'
    : '7c123ba3bab8a9610cf82544e6a77e44';

export const VALUE_SEPARATOR = '|||';
export const SOURCE_OF_ORDER = isBrowser ? 0 : 2;
export const SEND_ORDER_CONFIRMATION = false;
export const STATUS_EXCLUDED_FOR_COUNT = [
  'closed',
  'complete',
  'delivered',
  'canceled'
];

export const MY_ORDERS_COUNT_ENABLE = true;

export const ORDER_LIST_PAGE_SIZE = 5;
export const RETURN_ORDER_LIST_PAGE_SIZE = 10;

export const ACC_OPTION_FOR_LOGGED_OUT_USER = [
  'changecountry',
  'faq',
  'terms',
  'privacy'
];

export const IBAN_COUNTRY_CODE = {
  SA: 'SA',
  AE: 'AE',
  KW: 'KW',
  QA: 'QA',
  BH: 'BH'
};

export const IS_EVENT_LOGGING = COUNTRY.toLowerCase() !== 'in';
export const IS_RECOM_ON = COUNTRY.toLowerCase() !== 'in';

export const SEO_COUNTRY_LIST = {
  sa: { en: 'Saudi', ar: 'السعودية' },
  ae: { en: 'UAE', ar: 'الامارات' },
  kw: { en: 'Kuwait', ar: 'الكويت' },
  bh: { en: 'Bahrain', ar: 'البحرين' }
};

export const SEO_CITY_LIST = {
  sa: { en: ['Riyadh', 'Jeddah'], ar: ['الرياض', 'جدة'] },
  ae: { en: ['Dubai', 'Abu Dhabi'], ar: ['دبي', 'ابوظبي'] },
  kw: { en: ['Kuwait city', 'other cities'], ar: ['الكويت', 'الجهراء'] },
  bh: { en: ['Manama', 'other cities'], ar: ['المنامة', 'المحرق'] }
};
